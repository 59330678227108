<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :footer="false" :width="1280" :centered="true">
    <div class="menu-modal-container">
      <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
        <span slot="eventname" :title="value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''" slot-scope="value, record">{{value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''}}</span>
        <span slot="eventstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
        <span slot="eventendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
        <span slot="duration" slot-scope="value">
            <span>{{value?value+'分钟':''}}</span>
          </span>
        <span slot="eventstatus" slot-scope="value">
            <a-tag :color="eventstatusColors[value]||''">{{eventstatus[value]||''}}</a-tag>
          </span>
        <span slot="image" slot-scope="value, record">
            <a-button v-if="record.devicenum" type="link" size="small" @click="lookImage(value,record)">图片</a-button>
          </span>
        <span slot="video" slot-scope="value, record">
            <a-button type="link" size="small" @click="recover(value,record)">回放</a-button>
          </span>
        <span slot="operation" slot-scope="value, record">
          </span>
      </a-table>
      <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
    <imageBack :visible.sync="imageBackVisible" ref="imageBack" :EventData="eventData"></imageBack>
  </a-modal>
</template>

<script>
import pagination from "@/mixins/pagination";
import {mapGetters, mapState} from "vuex";
import {getEventrecordListByCondition} from "A/wlw";
import moment from "moment";
import {getCache, getItemFromArrayByKey} from "U/index";
import {eventstatus, eventstatusColors, finishSigns} from "@/json/wlwhistory";
import deviceselect from "@/mixins/deviceselect";
import imageBack from "V/jcxx/monitorfile/timeMonitoring/imageBack";
import {getMonitorPointinfoById, getMonitorPointListByCondition} from "A/monitoring";
export default {
  name: "EventDetail",
  mixins: [pagination,deviceselect],
  props: {
    visible: {
      default: false
    },
    modalDetail: {
      default: null
    },
  },
  components: {
    imageBack,
  },
  data() {
    return {
      moment,
      detailVisible:false,
      detailParams:{},
      systemNamesMap: [],
      finishSigns,
      eventstatus,
      eventstatusColors,
      systemNames:[],
      alarmTypes:[],
      feedbackList:[],
      bigInfluences:[],
      causeClassifyList:[],
      deviceCascaderSelected:[],
      modalVisible: false,
      queryParams:{
        monitorpointname:'',
        eventnum:'',
        eventcode:'',
        eventcodelist:[],
        starttime:'',
        endtime:'',
        eventtype:903,
        eventstatus:'',
        plannedwork:'',
        querytype:4,
        systemnameid:'',
        scenetypeid:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
          scopedSlots: { customRender: 'monitorpointname' },
        },
        {
          title: '事件类型',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
        },
        {
          title: '运行时长',
          dataIndex: 'duration',
          key: 'duration',
          ellipsis: true,
          scopedSlots: { customRender: 'duration' },
          width: 100,
        },
        {
          title: '开始时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
          width: 160,
        },
        {
          title: '结束时间',
          dataIndex: 'eventendtime',
          key: 'eventendtime',
          ellipsis: true,
          scopedSlots: { customRender: 'eventendtime' },
          width: 160,
        },
        {
          title: '完成标志',
          dataIndex: 'eventstatus',
          key: 'eventstatus',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'eventstatus' },
          width: 70
        },
        {
          title: '图片',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'image' }
        },
        {
          title: '回放',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'video' }
        },
      ],
      tableData: [],
      tableLoading: false,
      imageBackVisible:false,
      eventData:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['uploadHeaders', 'uploadBasemap']),
    modalTitle() {
      return '事件历史详情';
    }
  },
  watch: {
    deviceCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.devicecode = val[val.length-1];
      }else {
        this.queryParams.devicecode = '';
      }
    },
    visible(val) {
      if (val) {
        this.modalVisible = true;
        let dictionary = getCache('dictionary', true);
        if (dictionary) {
          this.bigInfluences = dictionary.bigInfluences;
          this.alarmTypes = dictionary.alarmTypes;
          this.alarmTypesMap = dictionary.alarmTypesMap;
          this.systemNames = dictionary.systemNames;
          this.systemNamesMap = dictionary.systemNamesMap;
          this.feedbackList = dictionary.feedbackList;
          this.abnormalTypes = dictionary.abnormalTypes;
          this.abnormalTypesMap = dictionary.abnormalTypesMap;
          this.equipmentTypes = dictionary.equipmentTypes;
          this.manageTypes = dictionary.manageTypes;
          this.recordTypes = dictionary.recordTypes;
          this.causeClassifyList = dictionary.causeClassifyList;
        }
        this.getTableData(true);
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.modalDetail,
        history: true,
        batterycar: true,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getEventrecordListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    lookImage(value,record){
      this.eventData=record;
      this.imageBackVisible=true;
    },
    recover(value,record){
      if(record.eventendtime==''||record.eventendtime==null){
        this.eventendtime=moment(new Date()).format('YYYYMMDDHHmmss');
        this.begintime=record.eventstarttime;
        this.endtime=this.eventendtime;
      }else{
        this.begintime=record.eventstarttime;
        this.endtime=record.eventendtime;
      }
      this.activeKey='tab8'
      let params = {
        monitorpointnum: record.monitorpointnum
      };
      getMonitorPointListByCondition(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.showLoading();
          let params = {
            monitorpointid:res.item[0].monitorpointid
          }
          getMonitorPointinfoById(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              if(res.item.deviceinfos && res.item.deviceinfos.length) {
                let prePages = [
                  {
                    name: '实时监控',
                    path: ''
                  }
                ];
                this.$router.push({
                  name: 'timeMonitoring',
                  params: {
                    monitorpointid: record.monitorpointid,
                    monitorpointnum: res.item.monitorpointnum,
                    userdepid:res.item.userdepid,
                    deviceinfos: res.item.deviceinfos,
                    sceneinfos: res.item.sceneinfos,
                    terminaltype: res.item.deviceinfos.length>0?res.item.deviceinfos[0].terminaltype:'',
                    monitorinfos:res.item.monitorinfos,
                    prePages: prePages,
                    begintime:this.begintime,
                    endtime:this.endtime,
                    activeKey:this.activeKey
                  }
                })
              }else {
                this.$message.error('该电梯无物联网设备');
              }
            }
          })
        }
      })
    },
  },
}
</script>
<style scoped>

</style>